import { Injectable } from '@angular/core';
import { ConfigDataResponse } from '../config/config.service';
import { Brand } from '../../shared/services/api/brand.service';
import { Category } from '../../shared/services/api/category.service';
import { User } from './authentication.service';
import CryptoES from 'crypto-es';

import { environment } from '../../../environments/environment';

const { AES, enc } = CryptoES;
@Injectable()
export class StorageManagerService {
    private static readonly TOKEN: string = 'token';
    private static readonly CONFIG: string = 'config';
    private static readonly BRAND: string = 'brand';
    private static readonly CATEGORY: string = 'category';
    private static readonly CART: string = 'cart';
    static secretKey = environment.secretKey;

    static encryptData(data: any): string {
        const jsonString = JSON.stringify(data);

        const encrypted = AES.encrypt(jsonString, this.secretKey).toString();

        return encrypted;
    }

    static decryptData(encryptedData: string): any {
        try {
            const bytes = AES.decrypt(encryptedData, this.secretKey);
            const decryptedData = bytes.toString(enc.Utf8);
            return JSON.parse(decryptedData);
        } catch (error) {
            this.clearAll();
            return null;
        }
    }

    static storeToken(token: Token) {
        localStorage.setItem(this.TOKEN, JSON.stringify(token));
    }
    static storeCartItem(cart: AddCart) {
        const cacheEntry = {
            cart,
        };
        sessionStorage.setItem(this.CART, JSON.stringify(cacheEntry));
    }
    public static getCartItem() {
        const cartString = sessionStorage.getItem(this.CART);
        if (cartString !== null) {
            const data = JSON.parse(cartString);

            return data.cart;
        }
        return null;
    }
    public static storeCartId(id: string) {
        localStorage.setItem('cartId', id);
    }
    public static removeCartId() {
        localStorage.removeItem('cartId');
    }
    public static getCartId() {
        const cartString = localStorage.getItem('cartId');

        return cartString;
    }
    static storeBrand(brand: Brand[]) {
        const cacheEntry = {
            timestamp: new Date().toISOString(),
            brand,
        };
        sessionStorage.setItem(this.BRAND, JSON.stringify(cacheEntry));
    }
    static storeCategory(catagory: Category[]) {
        const cacheEntry = {
            timestamp: new Date().toISOString(),
            catagory,
        };
        sessionStorage.setItem(this.CATEGORY, JSON.stringify(cacheEntry));
    }
    static getCategory() {
        const categoryString = sessionStorage.getItem(this.CATEGORY);
        if (categoryString !== null) {
            const data = JSON.parse(categoryString);
            const lastSyncAt = new Date(data.timestamp);
            const currentTime = new Date();
            const threeHours = 3 * 60 * 60 * 1000;
            if (currentTime.getTime() - lastSyncAt.getTime() < threeHours) {
                return data.catagory;
            }
        }
        return null;
    }

    public static getBrand() {
        const brandString = sessionStorage.getItem(this.BRAND);
        if (brandString !== null) {
            const data = JSON.parse(brandString);
            const lastSyncAt = new Date(data.timestamp);
            const currentTime = new Date();
            const threeHours = 3 * 60 * 60 * 1000;
            if (currentTime.getTime() - lastSyncAt.getTime() < threeHours) {
                return data.brand;
            }
        }
        return null;
    }

    static getToken() {
        const tokenString = localStorage.getItem(this.TOKEN);
        if (tokenString !== null) {
            return JSON.parse(tokenString);
        }
        return null;
    }

    static storeUser(user: User) {
        const encryptedpermissions = this.encryptData(user);
        localStorage.setItem('user', encryptedpermissions);
    }

    static getUser() {
        const userString = localStorage.getItem('user');
        if (userString !== null) {
            return this.decryptData(userString);
        }
        return null;
    }

    static storeConfig(config: ConfigDataResponse) {
        localStorage.setItem(this.CONFIG, JSON.stringify(config));
    }

    static getConfig() {
        const configString = localStorage.getItem(this.CONFIG);
        if (configString !== null) {
            return JSON.parse(configString);
        }
        return null;
    }

    static clearAll() {
        localStorage.removeItem('user');
        localStorage.removeItem('cartId');
        localStorage.removeItem('token');
    }
}

export interface Token {
    token?: string;
    refreshToken?: string;
    expiredTime?: number;
}

// export interface User {
//     id: number;
//     clientId: number;
//     firstName: string;
//     lastName: string;
//     emailAddress: string;
//     email_verified_at: string;
//     created_at: string;
//     updated_at: string;
//     status: string;
//     allocationType: string;
// }

interface Price {
    productId: number;
    tierId: number;
    price: number;
}

interface Embellishment {
    id: number;
    embellishmentName: string;
    description: string;
    unitPrice: string;
    allowCustomText: boolean;
    CustomText?: string;
}

interface VariantAttribute {
    attributeId: number;
    attributeName: string;
    attributeValueId: number;
    attributeValueName: string;
}

export interface AddCart {
    products: Cartproduct[];
}
export interface Cartproduct {
    price: number;
    id: number;
    name: string;
    image: string;
    sku: string;
    productId: number;
    quantity: number;
    prices: number;
    totalPrice: number;
    embellishments: Embellishment[];
    variantAttributes: VariantAttribute[];
    variants: number;
    variantName: string;
}
